// install (please make sure versions match peerDependencies)
// yarn add @nivo/core @nivo/pie
import { ResponsivePie } from "@nivo/pie";

// make sure parent container have a defined height when using
// responsive component, otherwise height will be 0 and
// no chart will be rendered.
// website examples showcase many properties,
// you'll often use just a few of them.

const MyResponsivePie = () => {
  const data = [
    {
      id: "Holders",
      value: 30,
      color: "rgba(255, 99, 132, 0.2)",
    },
    {
      id: "#1 Winner",
      value: 30,
      color: "hsl(245, 70%, 50%)",
    },
    {
      id: "#2 Winner",
      value: 20,
      color: "hsl(295, 70%, 50%)",
    },
    {
      id: "#3 Winner",
      value: 5,
      color: "hsl(260, 70%, 50%)",
    },
    {
      id: "#4 - #20 Winner",
      value: 10,
      color: "hsl(184, 70%, 50%)",
    },
  ];
  return (
    <ResponsivePie
      valueFormat={(number) => {
        return `${number}%`;
      }}
      data={data}
      margin={{ top: 40, right: 110, bottom: 60, left: 110 }}
      innerRadius={0.5}
      padAngle={0.7}
      cornerRadius={3}
      activeOuterRadiusOffset={8}
      borderWidth={1}
      borderColor={{
        from: "color",
        modifiers: [["darker", 0.2]],
      }}
      arcLinkLabelsSkipAngle={10}
      arcLinkLabelsTextColor="#fff"
      arcLinkLabelsThickness={2}
      arcLinkLabelsColor={{ from: "color" }}
      arcLabelsSkipAngle={10}
      arcLabelsTextColor={{
        from: "color",
        modifiers: [["darker", 2]],
      }}
      colors={{ scheme: "pastel1" }}
    />
  );
};

export default MyResponsivePie;
