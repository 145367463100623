import "./App.css";
import { useMemo } from "react";

import Minter from "./Minter";
import Minter2 from "./Minter2";

import * as anchor from "@project-serum/anchor";
import { clusterApiUrl } from "@solana/web3.js";
import { WalletAdapterNetwork } from "@solana/wallet-adapter-base";
import {
  getPhantomWallet,
  getSolflareWallet,
  getSolletWallet,
  getMathWallet,
} from "@solana/wallet-adapter-wallets";

import {
  ConnectionProvider,
  WalletProvider,
} from "@solana/wallet-adapter-react";

import { WalletDialogProvider } from "@solana/wallet-adapter-material-ui";
import { ThemeProvider, createTheme } from "@material-ui/core";

import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";

import PrizeDistribution from "./components/Distrubution";
import Faq from "./components/Faq";

ChartJS.register(ArcElement, Tooltip, Legend);

const data = {
  labels: ["Holders", "#1", "#2", "#3", "#4 - #20"],
  datasets: [
    {
      label: "deneme",
      data: [30, 30, 20, 5, 10],
      backgroundColor: [
        "rgba(255, 99, 132, 0.2)",
        "rgba(54, 162, 235, 0.2)",
        "rgba(255, 206, 86, 0.2)",
        "rgba(75, 192, 192, 0.2)",
        "rgba(153, 102, 255, 0.2)",
        "rgba(255, 159, 64, 0.2)",
      ],
      borderColor: [
        "rgba(255, 99, 132, 1)",
        "rgba(54, 162, 235, 1)",
        "rgba(255, 206, 86, 1)",
        "rgba(75, 192, 192, 1)",
        "rgba(153, 102, 255, 1)",
        "rgba(255, 159, 64, 1)",
      ],
      borderWidth: 1,
    },
  ],
};

const theme = createTheme({
  palette: {
    type: "dark",
  },
});

const candyMachineId = process.env.REACT_APP_CANDY_MACHINE_ID
  ? new anchor.web3.PublicKey(process.env.REACT_APP_CANDY_MACHINE_ID)
  : undefined;

const network = process.env.REACT_APP_SOLANA_NETWORK as WalletAdapterNetwork;

const rpcHost = process.env.REACT_APP_SOLANA_RPC_HOST!;
const connection = new anchor.web3.Connection(rpcHost);

const startDateSeed = parseInt(process.env.REACT_APP_CANDY_START_DATE!, 10);

const txTimeout = 30000; // milliseconds (confirm this works for your project)

const App = () => {
  const endpoint = useMemo(() => clusterApiUrl(network), []);

  const wallets = useMemo(
    () => [
      getPhantomWallet(),
      getSolflareWallet(),
      getSolletWallet(),
      getMathWallet(),
    ],
    []
  );

  function toggleMenu() {
    const menu = document.getElementById("mobileNavContainer")!;
    menu.classList.toggle("open-menu");
    console.log("pressed");
  }

  return (
    <div>
      <nav>
        <div className="nav-container">
          <img className="nav-logo" src="/img/logo.png" alt="" />
          <div className="social-icons">
            <a
              className="nav-social twitter"
              href="https://twitter.com/lotto_sol"
              target="_blank"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                x="0px"
                y="0px"
                width="48"
                height="48"
                viewBox="0 0 48 48"
              >
                <path
                  fill="#03A9F4"
                  d="M42,12.429c-1.323,0.586-2.746,0.977-4.247,1.162c1.526-0.906,2.7-2.351,3.251-4.058c-1.428,0.837-3.01,1.452-4.693,1.776C34.967,9.884,33.05,9,30.926,9c-4.08,0-7.387,3.278-7.387,7.32c0,0.572,0.067,1.129,0.193,1.67c-6.138-0.308-11.582-3.226-15.224-7.654c-0.64,1.082-1,2.349-1,3.686c0,2.541,1.301,4.778,3.285,6.096c-1.211-0.037-2.351-0.374-3.349-0.914c0,0.022,0,0.055,0,0.086c0,3.551,2.547,6.508,5.923,7.181c-0.617,0.169-1.269,0.263-1.941,0.263c-0.477,0-0.942-0.054-1.392-0.135c0.94,2.902,3.667,5.023,6.898,5.086c-2.528,1.96-5.712,3.134-9.174,3.134c-0.598,0-1.183-0.034-1.761-0.104C9.268,36.786,13.152,38,17.321,38c13.585,0,21.017-11.156,21.017-20.834c0-0.317-0.01-0.633-0.025-0.945C39.763,15.197,41.013,13.905,42,12.429"
                ></path>
              </svg>
            </a>
            <a
              className="nav-social discord"
              href="https://discord.gg/BDt8uJMenE"
              target="_blank"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                x="0px"
                y="0px"
                width="48"
                height="48"
                viewBox="0 0 48 48"
              >
                <path
                  fill="#8c9eff"
                  d="M40,12c0,0-4.585-3.588-10-4l-0.488,0.976C34.408,10.174,36.654,11.891,39,14c-4.045-2.065-8.039-4-15-4s-10.955,1.935-15,4c2.346-2.109,5.018-4.015,9.488-5.024L18,8c-5.681,0.537-10,4-10,4s-5.121,7.425-6,22c5.162,5.953,13,6,13,6l1.639-2.185C13.857,36.848,10.715,35.121,8,32c3.238,2.45,8.125,5,16,5s12.762-2.55,16-5c-2.715,3.121-5.857,4.848-8.639,5.815L33,40c0,0,7.838-0.047,13-6C45.121,19.425,40,12,40,12z M17.5,30c-1.933,0-3.5-1.791-3.5-4c0-2.209,1.567-4,3.5-4s3.5,1.791,3.5,4C21,28.209,19.433,30,17.5,30z M30.5,30c-1.933,0-3.5-1.791-3.5-4c0-2.209,1.567-4,3.5-4s3.5,1.791,3.5,4C34,28.209,32.433,30,30.5,30z"
                ></path>
              </svg>
            </a>
          </div>
        </div>
      </nav>
      <div className="main-wrapper">
        <header className="card top-card content-wrapper" id="link1">
          <div style={{ padding: "0 24px 0 24px 0" }}>
            <h3 style={{ opacity: 0.9, marginBottom: "3px" }}>Welcome To</h3>
            <h1 style={{ paddingBottom: "35px" }}>LottoSol</h1>
            <p>
              LottoSol is a lottery project in Solana Blockchain. NFT holders
              has special privilege from lottery games.
            </p>
          </div>
          <div className="minting-right">
            <img src="/img/lottery.png" />
          </div>
        </header>
        <div className="container prize">
          {/* <div className="prize-pool-wrapper">
            <div className="card">
              <h4 className="text-secondary-color">Current Prize Pool</h4>
              <span className="text-secondary-color">0 S0L</span>
            </div>
          </div> */}
          <div className="prize-distribution-wrapper">
            <div className="prize-distribution" style={{ height: 350 }}>
              <h4>Prize Distribution</h4>
              <div style={{ height: "100%", width: "100%" }}>
                <PrizeDistribution />
              </div>
            </div>
          </div>
          {/* <div className="prize-pool">
<div className="card">            <h4 className="text-secondary-color">Current Prize Pool</h4>
            30 S0L</div>
          </div>
                  <div className="prize-distrubition">
          <h4 className="text-secondary-color">Prize Distrubution</h4>
          <Doughnut data={data}  />
        </div> */}
        </div>
        <div className="content-wrapper">
          <Faq />
        </div>
        <footer>
          <div>
            <img className="logo" src="/img/logo.png" />
            <span className="divider"> | </span>
            <img
              className="powered-by-solana"
              src="/img/powered-by-solana.png"
            />
          </div>
        </footer>
      </div>
    </div>
  );
};

export default App;
