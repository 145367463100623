import React, { useEffect, useState } from "react";
import FaqComponent, { getRowOptions } from "react-faq-component";

const data = {
  title: "FAQ",
  rows: [
    {
      title: "What is LottoSol ?",
      content: `LottoSol is lottery project on Solana Blockchain.`,
      isExpanded: false,
    },
    {
      title: "What is mint price ?",
      content: "0.66 SOL",
    },
    {
      title: "How much supply",
      content: `666`,
    },
    {
      title: "What kind of benefits for holders ?",
      content: `Holders going to receive daily payments from price pool.`,
    },
    {
      title: "How much one ticket for lottery ?",
      content: `0.05 SOL. Unlimited per user. There will be special packages to save GAS.`,
    },
    {
      title: "What is the schedule for LottoSol ?",
      content: `Every week Tuesday to Sunday. Tickets claimable from 09:00 UTC to 20:00 UTC. Results going to be LIVE on our Discord server.`,
    },
    {
      title: "What about Royalties ?",
      content: `Holders going to receive daily payments from price pool.`,
    },
  ],
};

const styles = {
  bgColor: "transparent",
  titleTextColor: "rgb(68, 68, 68)",
  rowTitleColor: "rgb(68, 68, 68)",
  // rowContentColor: 'grey',
  arrowColor: "rgb(68, 68, 68)",
  rowContentPaddingTop: "10px",
  rowContentPaddingLeft: "15px",
  rowContentPaddingBottom: "10px",
  rowContentPaddingRight: "10px",
};

const config = {
  // animate: true,
  // arrowIcon: "V",
  // tabFocus: true
};

export default function Faq() {
  const [rows, setRowsOption] = useState(null);

  useEffect(() => {
    if (rows) {
      rows[0].expand();
    }
  }, [rows]);
  return (
    <div>
      <FaqComponent
        data={data}
        styles={styles}
        config={config}
        getRowOptions={setRowsOption}
      />
    </div>
  );
}
